import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryResumeArgs } from '../../generated/types';

export const GET_PUBLIC_RESUME = gql`
  query publicResume($userUuid: String!, $boardUuid: String!, $jobUuid: String, $resumeUuid: String!) {
    publicResume(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, resumeUuid: $resumeUuid) {
      uuid
      state
      isNew
      isPublic
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
      }
      updatedBy {
        uuid
      }
    }
  }
`;

export const useGetPublicResume = (options?: QueryHookOptions<Pick<Query, 'publicResume'>, QueryResumeArgs>) =>
  useQuery<Pick<Query, 'publicResume'>, QueryResumeArgs>(GET_PUBLIC_RESUME, options);

export const useLazyGetPublicResume = (options?: QueryHookOptions<Pick<Query, 'publicResume'>, QueryResumeArgs>) =>
  useLazyQuery<Pick<Query, 'publicResume'>, QueryResumeArgs>(GET_PUBLIC_RESUME, options);
