import { useRouter } from '../../../hooks/useRouter';
import { useGetPublicResume } from '../../../graph/queries/getPublicResume';

import { ViewWrapper } from './View.style';
import { GenericErrorAlert, InfoAlert } from '../../../components/atoms/InfoAlert';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { Copy } from '../../../components/atoms/Typography';
import { FunctionalIFrameComponent } from '../../../components/atoms/FunctionalIFrameComponent';
import { ResumePreview } from '../../../components/molecules/ResumePreview';

export const PublicBoardResumeView = () => {
  const { query: { userUuid = '', boardUuid = '', jobUuid = '', resumeUuid = '' } = {} } = useRouter();
  const {
    data: { publicResume } = {},
    loading,
    error,
  } = useGetPublicResume({
    variables: { userUuid, boardUuid, jobUuid, resumeUuid },
    fetchPolicy: 'network-only',
  });

  let resumeComponent = null;
  if (error) {
    resumeComponent = (
      <InfoAlert>
        <Copy>Sorry!</Copy>
        <Copy styleLevel={2}>
          The resume you are looking for does not exist or you do not have permission to view it.
        </Copy>
      </InfoAlert>
    );
  } else if (loading) {
    resumeComponent = (
      <>
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
      </>
    );
  } else if (publicResume) {
    resumeComponent = (
      <FunctionalIFrameComponent title="resume">
        <ResumePreview state={publicResume.state} />
      </FunctionalIFrameComponent>
    );
  } else {
    resumeComponent = <GenericErrorAlert key="error" />;
  }

  return <ViewWrapper>{resumeComponent}</ViewWrapper>;
};
